<template>
  <div class="bg-default">
    <!-- Header -->
    <div class="header bg-gradient-success py-7 py-lg-8 pt-lg-9">
      <b-container>
        <div class="header-body text-center mb-5">
          <b-row class="justify-content-center">
            <b-col xl="5" lg="6" md="8" class="px-5">
              <!-- <img
                alt="Logo"
                src="/img/logo.d21508bd_.png"
                height="100"
                class="mb-3"
              /> -->
              <h1 class="text-white">Suonnon</h1>
              <p class="text-lead text-white"></p>
            </b-col>
          </b-row>
        </div>
      </b-container>
      <div class="separator separator-bottom separator-skew zindex-100">
        <svg
          x="0"
          y="0"
          viewBox="0 0 2560 100"
          preserveAspectRatio="none"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
        >
          <polygon
            points="2560 0 2560 100 0 100"
            class="fill-default"
          ></polygon>
        </svg>
      </div>
    </div>
    <!-- Page content -->
    <b-container class="mt--8 pb-5">
      <b-row class="justify-content-center">
        <b-col lg="5" md="7">
          <b-card no-body class="bg-secondary border-0 mb-0">
            <b-card-body class="px-lg-5 py-lg-5">
              <b-form role="form" @submit.prevent="onSubmit">
                <b-form-input
                  v-model="model.user"
                  class="mb-3"
                  type="text"
                  name="username"
                  placeholder="Tên đăng nhập"
                ></b-form-input>
                <b-form-input
                  v-model="model.password"
                  class="mb-3"
                  type="password"
                  name="password"
                  placeholder="Mật khẩu"
                ></b-form-input>
                <div class="text-center">
                  <b-button
                    type="submit"
                    variant="danger"
                    class="my-4"
                    @click="login"
                    >Đăng nhập</b-button
                  >
                </div>
              </b-form>
            </b-card-body>
          </b-card>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>
<script>
export default {
  data() {
    return {
      model: {
        user: "",
        password: "",
        rememberMe: false
      }
    };
  },
  mounted() {
    let body = document.body;
    body.classList.add("bg-default");
  },
  methods: {
    onSubmit() {
      return false;
      // this will be called only after form is valid. You can do api call here to login
    },
    login() {
      let vm = this;
      vm.loginError = false;
      // Loading
      // vm.$vs.loading();

      vm.$store
        .dispatch("login", {
          name: vm.model.user,
          password: vm.model.password
        })
        .then(res => {
          console.log(res);
          window.location.href = "/";
          // vm.$vs.loading.close();
        })
        .catch(err => {
          this.makeToast("Lỗi", err, "danger");
        });
    },
    makeToast(title, content, variant, append = false) {
      this.$bvToast.toast(content, {
        title: title,
        autoHideDelay: 5000,
        appendToast: append,
        variant: variant,
        solid: true,
        toaster: "b-toaster-bottom-right mb-5"
      });
    }
  }
};
</script>
<style>
.bg-default {
  background-color: #172b4d !important;
}
.header {
  position: relative;
}
.pb-7,
.py-7 {
  padding-bottom: 6rem !important;
}
.pt-7,
.py-7 {
  padding-top: 6rem !important;
}
.bg-gradient-success {
  background: linear-gradient(87deg, #2dce89, #2dcecc) !important;
}
.pb-5,
.py-5 {
  padding-bottom: 3rem !important;
}
.mt--8,
.my--8 {
  margin-top: -8rem !important;
}
.mb-7,
.my-7 {
  margin-bottom: 6rem !important;
}
@media (min-width: 1200px) {
  .separator-skew {
    height: 70px;
  }
}

.separator-bottom {
  top: auto;
  bottom: 0;
}

.separator {
  position: absolute;
  pointer-events: none;
  left: 0;
  right: 0;
  width: 100%;
  overflow: hidden;
  transform: translateZ(0);
}
.bg-secondary {
  background-color: #f7fafc !important;
}
.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.05);
  border-radius: 0.375rem;
}
.card-header:first-child {
  border-radius: calc(0.375rem - 1px) calc(0.375rem - 1px) 0 0;
}
.btn-primary,
.btn-primary:hover {
  color: #fff;
  background-color: #5e72e4;
  border-color: #5e72e4;
}
</style>
